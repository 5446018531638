import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        redirect: '/user'
    },{
		path: '/user',
		name: 'user',
		meta: {
			title: '用户列表'
		},
		component: () => import (/* webpackChunkName: "subindex" */
		'../views/twitter/user.vue')
	},{
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `系统记录`;
    const role = localStorage.getItem('l_username');
    if (!role && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
});

export default router;